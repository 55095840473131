import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { MainContainer, Separator } from 'components/PageCareer/styled';
import Section from 'components/Section';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';

const pageQuery = graphql`
  {
    allProjectlistYaml {
      nodes {
        slug
        name
        bgColor
        textColor
        headingsColor
        sections {
          image {
            publicURL
          }
          heading
          text
          html
          double {
            bgImage {
              publicURL
            }
            right {
              heading
              text
              image {
                publicURL
              }
            }
            left {
              heading
              text
            }
          }
          gallery {
            border
            images {
              publicURL
            }
          }
          button {
            link
            text
          }
        }
      }
    }
  }
`;

const Project = ({ pageContext }: any) => {
  const { projectSlug } = pageContext;

  const projects = useStaticQuery(pageQuery).allProjectlistYaml.nodes;
  const siteData = projects.find((a: any) => a.slug === projectSlug);

  useEffect(() => {
    !siteData && navigate('/references', { replace: true });
  }, [siteData]);

  if (!siteData) {
    return <></>;
  }

  return (
    <>
      <SEO title={siteData.name} />
      <GlobalStyle bgColor={siteData.bgColor} />
      <NavigationBar />
      <Separator />

      <MainContainer>
        <StyledSection>
          {siteData.sections.map((data: any, index: number) => (
            <Section headingsColor={siteData.headingsColor} textColor={siteData.textColor} {...data} key={index} />
          ))}
        </StyledSection>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Project;
