import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const style = css`
  display: block;
  margin: auto;
  width: 90%;
  max-width: 520px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.098em;
  font-size: 16px;
  background: none;
  color: ${({ theme }) => theme.color.text.primary};
  text-decoration: none;
  border: 2px solid ${({ theme }) => theme.color.text.accent};
  padding: 18px 0;
  text-align: center;
  margin-bottom: 50px;
  :hover {
    background: ${({ theme }) => theme.color.text.accent};
  }
`;

export const LinkWrapper = styled(Link)`
  ${style}
`;

export const ExternalLinkWrapper = styled.a`
  ${style}
`;

export const ButtonWrapper = styled.button`
  width: 90%;
  max-width: 520px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.098em;
  font-size: 16px;
  background: none;
  color: ${({ theme }) => theme.color.text.primary};
  text-decoration: none;
  border: 2px solid ${({ theme }) => theme.color.text.accent};
  padding: 18px 0;
  text-align: center;
  margin-bottom: 50px;
  :hover {
    background: ${({ theme }) => theme.color.text.accent};
  }
`;

export const InputWrapper = styled.input`
  width: 100%;
  max-width: 520px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.098em;
  font-size: 16px;
  background: none;
  color: ${({ theme }) => theme.color.text.primary};
  text-decoration: none;
  border: 2px solid ${({ theme }) => theme.color.text.accent};
  padding: 18px 0 18px 0;
  text-align: center;
  margin-bottom: 50px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.color.text.accent};
  }
`;
