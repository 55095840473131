import { Col } from 'antd';
import { RedLink } from 'components/CommonComponents/RedButtons';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ParagraphBody, ParagraphHead, ParagraphImage, StyledGalleryImage, StyledRow, StyledStandAloneImage } from './styled';

interface Column {
  text: string | null;
  heading: string | null;
  image: {
    publicURL: string;
  };
}

interface Props {
  bgColor: string;
  textColor: string;
  headingsColor: string;
  text: string | null;
  html: string | null;
  button: {
    text: string;
    link: string;
  } | null;
  gallery: {
    images: {
      publicURL: string;
    }[];
    border: boolean;
  };
  image: {
    publicURL: string;
  };
  heading: string | null;
  double: null | {
    left: Column[];
    right: Column[];
    bgImage: {
      publicURL: string;
    };
  };
}

interface ColProps {
  headingsColor: string;
  textColor: string;
}

const renderColumn = ({ headingsColor, textColor }: ColProps) => (sec: Column, index: number) => {
  if (sec.image) {
    return <ParagraphImage src={sec.image.publicURL} key={index} />;
  }
  if (sec.heading) {
    return (
      <ParagraphHead style={{ fontWeight: 700 }} color={headingsColor} key={index}>
        {sec.heading}
      </ParagraphHead>
    );
  }
  if (sec.text) {
    return (
      <ParagraphBody color={textColor} key={index}>
        {ReactHtmlParser(sec.text)}
      </ParagraphBody>
    );
  }

  throw new Error('Unknown type!');
};

const Section = (data: Props) => {
  const { gallery, button, image, double, heading, text, html, headingsColor, textColor } = data;
  const images = gallery?.images || [];

  if (images.length) {
    return (
      <StyledRow style={{ textAlign: 'center' }} gutter={[16, 16]} justify="center" align="middle">
        {images.map(({ publicURL }: { publicURL: string }, index) => (
          <Col xs={24} md={24 / images.length} key={index}>
            <StyledGalleryImage borderColor={gallery.border ? headingsColor : 'transparent'} src={publicURL} />
          </Col>
        ))}
      </StyledRow>
    );
  }

  if (image) {
    return (
      <StyledRow gutter={[16, 16]} justify="center" align="middle">
        <Col xs={24} style={{ textAlign: 'center' }}>
          <StyledStandAloneImage src={image.publicURL} />
        </Col>
      </StyledRow>
    );
  }

  if (html) {
    return (
      <StyledRow gutter={[16, 16]} justify="center" align="middle" style={{ width: '100%' }}>
        <Col span={24}>
          <ParagraphBody color={textColor}>{ReactHtmlParser(html)}</ParagraphBody>
        </Col>
      </StyledRow>
    );
  }

  if (text) {
    return (
      <StyledRow gutter={[16, 16]} justify="center" align="middle">
        <Col span={24}>
          <ParagraphBody color={textColor}>{ReactHtmlParser(text)}</ParagraphBody>
        </Col>
      </StyledRow>
    );
  }

  if (double) {
    const { left, right, bgImage } = double;
    const render = renderColumn({ headingsColor, textColor });

    const hasImages = left.some(a => a.image) || right.some(a => a.image);

    const bgStyle = !!bgImage
      ? {
          backgroundSize: 'contain',
          backgroundImage: `url(${bgImage.publicURL})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }
      : {};

    return (
      <StyledRow style={{ ...bgStyle }} gutter={[16, 16]} justify="center" align={hasImages ? 'middle' : 'top'}>
        <br />
        <Col xs={24} md={12} style={{ textAlign: 'left' }}>
          {left.map(render)}
        </Col>
        <Col xs={24} md={12} style={{ textAlign: 'left' }}>
          {right.map(render)}
        </Col>
        <br />
      </StyledRow>
    );
  }

  if (heading) {
    return (
      <StyledRow justify="center" align="middle">
        <Col span={24}>
          <ParagraphHead color={headingsColor}>{heading}</ParagraphHead>
        </Col>
      </StyledRow>
    );
  }

  if (button) {
    return (
      <StyledRow justify="center" align="middle" style={{ width: '100%' }}>
        <Col span={24}>
          <RedLink text={button.text} to={button.link} />
        </Col>
      </StyledRow>
    );
  }

  console.warn({ data });
  throw new Error('Unknown section type!');
};

export default Section;
