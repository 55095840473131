import { Row } from 'antd';
import styled from 'styled-components';

interface ParaProp {
  color: string;
}

interface GalleryImageProp {
  borderColor: string;
}

export const ParagraphHead = styled.h2<ParaProp>`
  color: ${({ color }) => color};
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-weight: 700;
  line-height: 56px;
  margin: 50px 0 20px;
`;

export const ParagraphBody = styled.p<ParaProp>`
  color: ${({ color }) => color};
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 10px;
`;

export const ParagraphImage = styled.img`
  width: 100%;
  padding: 20px;
`;

export const StyledRow = styled(Row)``;

export const StyledGalleryImage = styled.img<GalleryImageProp>`
  width: 100%;
  padding: 30px 0;
  border-radius: 30px;
  border-color: ${({ borderColor }) => borderColor};
  border-style: solid;
  max-width: 500px;

  @media screen and (max-width: ${({ theme }) => theme.layout.md}px) {
    padding: 20px 0;
    border-radius: 15px;
  }
`;

export const StyledStandAloneImage = styled.img`
  width: 100%;
`;
