import React from 'react';
import { ButtonWrapper, ExternalLinkWrapper, InputWrapper, LinkWrapper } from './styled';

interface PropsButton {
  text: string;
  onClick?: () => void;
}

interface PropsLink {
  text: string;
  to: string;
}

interface PropsSubmit {
  disabled: boolean;
  value: string;
  onClick?: () => void;
}

export const RedButton = ({ text, onClick }: PropsButton) => {
  return <ButtonWrapper onClick={onClick}>{text}</ButtonWrapper>;
};

export const RedSubmit = ({ disabled, value, onClick }: PropsSubmit) => {
  return <InputWrapper disabled={disabled} type={'submit'} value={value} onClick={onClick} />;
};

export const RedLink = ({ text, to }: PropsLink) => {
  const isRelative = to.indexOf('http') < 0 || to.indexOf('mailto') < 0;
  return !isRelative ? (
    <ExternalLinkWrapper href={to} target="_blank">
      {text}
    </ExternalLinkWrapper>
  ) : (
    <LinkWrapper to={to}>{text}</LinkWrapper>
  );
};
